<template>
  <div class="row impr-relation-table-info-row">
    <div class="col-12" style="padding: 0 !important;">
      <div class="card bg-primary widget-chart text-white card-border impr-relation-table-info-header">
        <div class="impr-relation-table-info-title">
          <div class="icon-wrapper rounded-circle impr-relation-table-info-title-icon">
            <div class="icon-wrapper-bg bg-white opacity-1"></div>
            <i class="lnr-bubble text-white"></i>
          </div>
          <div class="impr-relation-table-info-title-detail">{{popupParam.relationTableTitle}}</div>
        </div>
        <div class="text-white opacity-8 impr-relation-table-info-navi">
          <span class="pl-1">{{popupParam.relationTableNavi}}</span>
        </div>
      </div>
      <div class="card bg-white widget-chart card-border">
        <div class="impr-relation-table-info-contents" v-html="$comm.convertEnter(popupParam.relationTableContents)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'imprRelationTagPop',
  props: {
    popupParam: {
      type: Object,
      default() {
        return {
          sopImprovementId: null,
          ibmTaskTypeCd: null,
          ibmTaskUnderTypeCd: null,
          relationTableKey: null,
          relationTableTitle: '연결된 업무가 없습니다..',
          relationTableNavi: '',
          relationTableContents: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
<style scoped>
.impr-relation-table-info-row {
  width: 100%;
}
.impr-relation-table-info-header {
  padding: 0 1rem 1rem 1rem;
}
.impr-relation-table-info-title {
  margin: 1rem auto;
}
.impr-relation-table-info-title-icon {
  margin-right: 10px;
  display:inline-flex;
  width: 15px;
  height: 15px;
}
.impr-relation-table-info-title-icon i {
  font-size: 1.1rem;
}
.impr-relation-table-info-title-detail {
  font-weight: bold;
  font-size: 1.1rem;
  display: inline;
  line-height: 1;
  text-align: center;
}
.impr-relation-table-info-contents {
  margin: -0.5rem 0 0;
  display: block;
  text-align: left;
}
.impr-relation-table-info-navi {
  text-align: left;
}
</style>