var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row impr-relation-table-info-row" }, [
    _c(
      "div",
      { staticClass: "col-12", staticStyle: { padding: "0 !important" } },
      [
        _c(
          "div",
          {
            staticClass:
              "card bg-primary widget-chart text-white card-border impr-relation-table-info-header",
          },
          [
            _c("div", { staticClass: "impr-relation-table-info-title" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "impr-relation-table-info-title-detail" },
                [_vm._v(_vm._s(_vm.popupParam.relationTableTitle))]
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "text-white opacity-8 impr-relation-table-info-navi",
              },
              [
                _c("span", { staticClass: "pl-1" }, [
                  _vm._v(_vm._s(_vm.popupParam.relationTableNavi)),
                ]),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "card bg-white widget-chart card-border" }, [
          _c("div", {
            staticClass: "impr-relation-table-info-contents",
            domProps: {
              innerHTML: _vm._s(
                _vm.$comm.convertEnter(_vm.popupParam.relationTableContents)
              ),
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "icon-wrapper rounded-circle impr-relation-table-info-title-icon",
      },
      [
        _c("div", { staticClass: "icon-wrapper-bg bg-white opacity-1" }),
        _c("i", { staticClass: "lnr-bubble text-white" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }